const ALGOLYTICS_DOMAIN = "//app.scoring.one/";

declare global {
    interface Window {
        sce: Partial<ISce>;
    }
}

interface ISce {
    invoked: unknown;
    loadScriptFlag: unknown;
    baseUrl: unknown;
    writeKey: unknown;
    CODES_V: string;
    buffer: unknown[];
    event: (eventType: string, arg: unknown) => void;
    sendData: (eventType: string, arg: unknown, timestamp: null, scenario: string) => void;
}

let isAlgolyticsInitialized = false;
let showAlgolyticsLogs = false;

/**
 * Init
 */
export const initAlgolytics = (algolyticsId: string | undefined, showEventLogs = false, scenario: string = "www_events"): void => {
    // show Algolytics log - useful in staging environment
    if (showEventLogs) {
        showAlgolyticsLogs = showEventLogs;
    }

    // if there is no ID provided, do not initialize
    if (!algolyticsId) {
        return;
    }

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser" && typeof window === "undefined") {
        return;
    }
    // initialize only once
    if (isAlgolyticsInitialized) {
        return;
    }
    // initialize
    (function (base_url: string, write_key: string) {
        window.sce = window.sce || {};
        const sce = window.sce;

        // already invoked
        if (sce.invoked) {
            if (window.console && console.error) {
                console.error("Probably, code already included");
            }
            return;
        }

        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        sce.invoked = true;
        sce.loadScriptFlag = false;
        // ('https:' === document.location.protocol ? 'https://' : 'http://')
        sce.baseUrl = base_url;
        sce.writeKey = write_key;
        sce.CODES_V = "0.3";
        sce.buffer = sce.buffer || [];

        // fetch main script
        const firstScriptElem = document.getElementsByTagName("script")[0];
        const newScriptElem = document.createElement("script");
        newScriptElem.async = true;
        newScriptElem.src = `${sce.baseUrl}exposed/sendData.js`;
        firstScriptElem != null && firstScriptElem.parentNode != null && firstScriptElem.parentNode.insertBefore(newScriptElem, firstScriptElem);

        // public API
        sce.event = function (eventType: string, arg: unknown) {
            if (sce.loadScriptFlag) {
                sce.sendData?.(eventType, arg, null, scenario);
            } else {
                sce.buffer?.push([Date.now(), eventType, arg, scenario]);
            }
        };
    })(ALGOLYTICS_DOMAIN, algolyticsId);
    isAlgolyticsInitialized = true;
};

/**
 * Hit
 */
export const hitAlgolytics = (collection: string, data: Object = {}) => {
    if (showAlgolyticsLogs) {
        console.log("%c Algolytics Event:", "color: blue", collection, data);
    }

    const sessionId = getCookie("analyticssessionid");

    const payload = {session_id: sessionId ? sessionId : undefined, ...data};

    if (!isAlgolyticsInitialized) {
        return;
    }

    window.sce && window.sce.event?.(collection, payload);
};

function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length >= 2) {
        const lastElement = parts.pop();
        return lastElement && lastElement.split(";").shift();
    }
    return null;
}

/**
 * Helper
 */
export function delayHit(cb: (...payload: Object[]) => void, time: number) {
    let hitTimeout = 0;
    return (...args: Object[]) => {
        clearTimeout(hitTimeout);
        hitTimeout = window.setTimeout(() => cb(...args), time);
    };
}

export const phoneNumberClickAlgolytics = delayHit((payload: Object) => hitAlgolytics("phone_number", payload), 500);
