import React from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text";

import {ISelectGroupOption, SelectGroupOptionValueTypes} from "./SelectGroupField";

import * as styles from "./SelectGroup.module.css";

interface IProps<ValueType extends SelectGroupOptionValueTypes> extends ISelectGroupOption<ValueType> {
    name: string;
    onChange: (name: string, value: ValueType) => void;
}

export const SelectGroupItem = <ValueType extends SelectGroupOptionValueTypes>(props: IProps<ValueType>) => {
    const onClick = () => {
        if (props.isDisabled) {
            return;
        }
        props.onChange(props.name, props.value);
    };

    const cn = classNames(
        styles.checkboxHolder,
        props.isActive && styles.checkboxHolderActive,
        props.isActive && props.isNonActive && styles.checkboxHolderInactive, // that probably means it is "within selected in range"?
        props.isDisabled && styles.checkboxHolderDisabled
    );

    return (
        <div className={cn} onClick={onClick}>
            <Text variant="info_txt_1" align="center">
                {props.label}
            </Text>
        </div>
    );
};
