import React from "react";
import classNames from "classnames";
import cn from "classnames";

import {PlaceholderIcon} from "@pg-design/icons-module";

import {placeholderBase, placeholderIcon} from "./Placeholder.module.css";

export const Placeholder = (props: {className?: string; iconClassName?: string}) => {
    const cs = classNames(placeholderBase, props.className);
    return (
        <div className={cs}>
            <PlaceholderIcon className={cn(placeholderIcon, props.iconClassName)} />
        </div>
    );
};
