// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .ZhWMB7Ymw5fZVXCSkMsM {
        position: absolute;
        background: #ffffff;
        overflow: auto;
        border-radius: calc(var(--globals-baseUnit) * 2);
        outline: none;
        padding: 2rem;
    }
    .pDtYhiasunn2omAnHSHg {
        position: fixed;
        inset: 0;
        background-color: rgba(from var(--colors-secondary) R G B / 0.75);
        width: 100%;
    }

    .AC_RiVboqAVidxXcYQJQ {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 100%;
        height: 100%;
    }
}

`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/system/modal/src/components/ModalCore.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,mBAAmB;QACnB,cAAc;QACd,gDAAgD;QAChD,aAAa;QACb,aAAa;IACjB;IACA;QACI,eAAe;QACf,QAAQ;QACR,iEAAiE;QACjE,WAAW;IACf;;IAEA;QACI,kBAAkB;QAClB,aAAa;QACb,sBAAsB;QACtB,UAAU;QACV,YAAY;IAChB;AACJ","sourcesContent":["@layer packages {\n    .defaultContentStyle {\n        position: absolute;\n        background: #ffffff;\n        overflow: auto;\n        border-radius: calc(var(--globals-baseUnit) * 2);\n        outline: none;\n        padding: 2rem;\n    }\n    .overlay {\n        position: fixed;\n        inset: 0;\n        background-color: rgba(from var(--colors-secondary) R G B / 0.75);\n        width: 100%;\n    }\n\n    .childrenHolder {\n        position: relative;\n        display: flex;\n        flex-direction: column;\n        flex: 100%;\n        height: 100%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export var defaultContentStyle = `ZhWMB7Ymw5fZVXCSkMsM`;
export var overlay = `pDtYhiasunn2omAnHSHg`;
export var childrenHolder = `AC_RiVboqAVidxXcYQJQ`;
export default ___CSS_LOADER_EXPORT___;
