// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .e55XYat09WQaC1KxLxXe {
        padding: 0;
        list-style: none;
        display: flex;
        font-size: 1.2rem;
        width: 100%;
        align-items: stretch;
        border: 1px solid var(--colors-gray-600);
        border-radius: var(--globals-baseUnit);
        overflow: hidden;
        word-wrap: normal;
    }

    .znnLVT_xFtst8Z_lY_Nw {
        height: 4.8rem;
        min-height: 4.8rem;
    }

    .jkMu_pxCmgmvl4PBK7z4 {
        padding: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1;
        border-right: 1px solid var(--colors-gray-600);
        transition: box-shadow 0.2s ease-out;
        cursor: pointer;

        &:last-of-type {
            border-right: none;
        }

        @media (min-width: 1024px) {
            font-size: 1.4rem;

            &:not(.oC0RKmwXF77UYtobDkQl):hover {
                box-shadow: inset 0 0 0 500px rgba(from var(--colors-gray-600) R G B / 0.3);
            }
        }
    }

    .oC0RKmwXF77UYtobDkQl {
        cursor: not-allowed;
        color: #ccc;
    }

    .ImJ4WxJPBOUae4bPH8T4 {
        background: var(--colors-primary);
    }

    .C677vKaJsVoJlkgIYoN_ {
        background: #ececec;

    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/select_group/src/components/SelectGroup.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;QACV,gBAAgB;QAChB,aAAa;QACb,iBAAiB;QACjB,WAAW;QACX,oBAAoB;QACpB,wCAAwC;QACxC,sCAAsC;QACtC,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,cAAc;QACd,kBAAkB;IACtB;;IAEA;QACI,eAAe;QACf,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,SAAS;QACT,8CAA8C;QAC9C,oCAAoC;QACpC,eAAe;;QAEf;YACI,kBAAkB;QACtB;;QAEA;YACI,iBAAiB;;YAEjB;gBACI,2EAA2E;YAC/E;QACJ;IACJ;;IAEA;QACI,mBAAmB;QACnB,WAAW;IACf;;IAEA;QACI,iCAAiC;IACrC;;IAEA;QACI,mBAAmB;;IAEvB;AACJ","sourcesContent":["@layer packages {\n    .checkboxList {\n        padding: 0;\n        list-style: none;\n        display: flex;\n        font-size: 1.2rem;\n        width: 100%;\n        align-items: stretch;\n        border: 1px solid var(--colors-gray-600);\n        border-radius: var(--globals-baseUnit);\n        overflow: hidden;\n        word-wrap: normal;\n    }\n\n    .checkboxListField {\n        height: 4.8rem;\n        min-height: 4.8rem;\n    }\n\n    .checkboxHolder {\n        padding: 1.3rem;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex: 1 1;\n        border-right: 1px solid var(--colors-gray-600);\n        transition: box-shadow 0.2s ease-out;\n        cursor: pointer;\n\n        &:last-of-type {\n            border-right: none;\n        }\n\n        @media (min-width: 1024px) {\n            font-size: 1.4rem;\n\n            &:not(.checkboxHolderDisabled):hover {\n                box-shadow: inset 0 0 0 500px rgba(from var(--colors-gray-600) R G B / 0.3);\n            }\n        }\n    }\n\n    .checkboxHolderDisabled {\n        cursor: not-allowed;\n        color: #ccc;\n    }\n\n    .checkboxHolderActive {\n        background: var(--colors-primary);\n    }\n\n    .checkboxHolderInactive {\n        background: #ececec;\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var checkboxList = `e55XYat09WQaC1KxLxXe`;
export var checkboxListField = `znnLVT_xFtst8Z_lY_Nw`;
export var checkboxHolder = `jkMu_pxCmgmvl4PBK7z4`;
export var checkboxHolderDisabled = `oC0RKmwXF77UYtobDkQl`;
export var checkboxHolderActive = `ImJ4WxJPBOUae4bPH8T4`;
export var checkboxHolderInactive = `C677vKaJsVoJlkgIYoN_`;
export default ___CSS_LOADER_EXPORT___;
