import React from "react";
import classNames from "classnames";

import {IFormFieldProps} from "@pg-mono/formik-utils";

import {ISelectGroupOption, SelectGroupOptionValueTypes} from "./SelectGroupField";
import {SelectGroupItem} from "./SelectGroupItem";

import * as styles from "./SelectGroup.module.css";

type MultiSelectGroupFormFieldProps<OptionValueType> = Omit<IFormFieldProps<string, OptionValueType[]>, "checked">;

export interface ISelectGroupMultiFieldProps<OptionValueType extends SelectGroupOptionValueTypes> extends MultiSelectGroupFormFieldProps<OptionValueType> {
    className?: string;
    options?: ISelectGroupOption<OptionValueType>[];
    availableOptions?: OptionValueType[];
    id?: string;
    required?: boolean;
    onClick?: () => void;
}

export const SelectGroupMultiField = <OptionValueType extends SelectGroupOptionValueTypes>(props: ISelectGroupMultiFieldProps<OptionValueType>) => {
    const onMultiSelectChange = (name: string, value: OptionValueType) => {
        if (props.options && props.options.findIndex((option) => option.value === value) > -1) {
            if (props.value.findIndex((option) => option === value) > -1) {
                props.onChange(
                    props.name,
                    props.value.filter((item) => item !== value)
                );
            } else {
                props.onChange(props.name, props.value.concat(value));
            }
        }
    };

    const elementStatus = (optionValue: OptionValueType) => {
        if (props.availableOptions?.findIndex((option) => option.toString() === optionValue.toString()) === -1) {
            return "disabled";
        } else if (props.value && props.value.findIndex((val) => val.toString() === optionValue.toString()) > -1) {
            return "active";
        } else {
            return "non-active";
        }
    };

    const cn = classNames(styles.checkboxList, props.className);

    return (
        <div className={cn} onClick={props.onClick}>
            {props.options &&
                props.options.map((option) => (
                    <SelectGroupItem
                        key={option.value}
                        name={props.name}
                        value={option.value}
                        onChange={onMultiSelectChange}
                        label={option.label}
                        isActive={elementStatus(option.value) === "active"}
                        isNonActive={elementStatus(option.value) === "non-active"}
                        isDisabled={elementStatus(option.value) === "disabled"}
                    />
                ))}
        </div>
    );
};
